.property-social{
	display:flex;
	justify-content:center;
	a{
		height:56px;
		width:56px;
		border-radius:100%;
		background:rgba($primary,0.2);
		color:$primary;
		font-size:30px;
		display:block;
		text-align:center;
		line-height:56px;
		margin:0px 5px;
		&:hover{
			background:$primary;
			color:$white;
		}
		@include respond ('laptop'){
			height:41px;
			width:41px;
			line-height:41px;
			font-size: 18px;
		}
	}
}
.profile-icon{
	display:block;
	height:45px;
	width:45px;
	border-radius:100%;
	position:absolute;
	bottom:-4px;
	right:-4px;
	background:$primary;
	color:$white;
	font-size: 23px;
	line-height:45px;
	text-align:center;
	&:hover{
		background:$white;
		color:$primary;
	}
}
.front-view{
	position:relative;
	.info{
		position:absolute;
		bottom:50px;
		left:50px;
		width:40%;
		height:fit-content;
		p{
			color:$white;
			font-weight: 400;
			line-height: 1.5;
			font-size: 15px;
		}	
		@include respond ('phone'){
			bottom: 60px;
			left: 50%;
			transform: translateX(-50%);
			width: 80%;
			text-align: center;
		}
	}
	.buttons{
		position:absolute;
		top:50px;
		right:50px;
		@include respond ('phone'){
			top:20px;
			right:20px;
		}
	}
	img{
		height:660px;
		object-fit:cover;
		width:100%;
		border-radius:$radius;
		@include respond ('laptop'){
			height:450px;	
		}
		@include respond ('phone'){
			height:300px;	
		}
	}
}
.front-view-slider{
	.owl-dots{
		position:absolute;
		display:flex;
		bottom:50px;
		right:50px;
		.owl-dot{
			height:7px;
			cursor:pointer;
			width:37px;
			margin: 0 5px;
			border-radius:10px;
			@include transitionMedium;
			background:rgba($white,0.3);
			&.active{
				width:67px;
				@include transitionMedium;
				background:$white;
			}
		}
		@include respond ('phone'){
			left: 50%;
			bottom:30px;
			transform: translateX(-50%);
		}
	}
}
.image-gallery{
	img{
		border-radius:$radius;
	}
	.owl-prev,.owl-next {
		position: absolute;
		width: 60px;
		height: 60px;
		line-height: 60px;
		border-radius: $radius;
		top: 50%;
		background: $white;
		color: $primary;
		font-size: 18px;
		margin-top: -30px;
		text-align: center;
		-webkit-transition: all 0.5s;
		-ms-transition: all 0.5s;
		transition: all 0.5s;
		cursor: pointer;
		box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.13);
		@include respond('phone'){
			width:45px;
			height:45px;
			line-height:45px;
		}
	}
	
	.owl-prev {
		left: -45px;
		@include respond('phone'){
			left:-25px;
		}
	}
	.owl-next {
		right: -45px;
		@include respond('phone'){
			right:-25px;
		}
	}
}
.property-features{
	ul{
		li{
			width:220px;
			display:flex;
			align-items:center;
			color:$black;
			float:left;
			padding: 10px 0px;
			font-weight:500;
			i{
				color:$primary;
				font-size:26px;
				margin-right:10px;
			}
			@include respond ('phone'){
				width: auto;
				margin-right: 15px;
			}
		}
	}
}